import classes from "./FallbackRender.module.css";
import { ActionButton } from "./utils/styledComponents";

function FallbackRender({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div className={classes["main-container"]} role="alert">
      <div>
        <p>Algo salió mal:</p>
        <pre className={classes["error-message"]} style={{ color: "red" }}>
          {error.message}
        </pre>
        <p>Se envió un reporte con los detalles del error.</p>
      </div>
      <ActionButton onClick={resetErrorBoundary}>
        <div style={{ color: "#2f4250" }}>Volver a cargar</div>
      </ActionButton>
    </div>
  );
}

export default FallbackRender;
