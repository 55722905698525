import AWS from "aws-sdk";

export const notifyError = async () => {
  console.log("Error check triggered");
  const errorBatch = JSON.parse(localStorage.getItem("errorBatch"));

  if (!errorBatch || !Array.isArray(errorBatch)) return;

  const errorsToNotify = errorBatch
    .filter((entry) => !entry.didNotify)
    .map((entry, index) => ({ index, error: entry.error }));

  if (!errorsToNotify.length) return;

  console.log("errors will be sent to Lambda");

  try {
    // Create an instance of the Lambda service
    const lambda = new AWS.Lambda();

    // Define the parameters for invoking the Lambda function
    const params = {
      FunctionName: "CounterAppErrorLogHandler",
      Payload: JSON.stringify(errorsToNotify),
    };

    // Wrap the Lambda invocation in a Promise
    return new Promise((resolve, reject) => {
      // Invoke the Lambda function
      lambda.invoke(params, (err, data) => {
        if (err) {
          console.error("Error calling Lambda function:", err);
          reject(false); // Reject the promise with the error
        } else {
          const response = JSON.parse(data.Payload);

          console.log(
            "Response from Lambda error notification service",
            response
          );

          const updatedErrorBatch = markNotified(errorBatch);

          localStorage.setItem("errorBatch", JSON.stringify(updatedErrorBatch));

          resolve(response.body); // Resolve the promise with the response data
        }
      });
    });
  } catch (error) {
    console.error("Error-logging Lambda function failed:", error);
  }
};

function markNotified(arrayOfObjects) {
  // Iterate through the array of objects
  for (let obj of arrayOfObjects) {
    // Set the value of the didNotify property to true
    obj.didNotify = true;
  }
  // Return the modified array
  return arrayOfObjects;
}
