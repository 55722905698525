import React, { useContext, useState, useEffect } from "react";
import DataContext from "../store/data-context";
import { Button } from "@aws-amplify/ui-react";
import styles from "./Counter.module.css";

function Counter({ gender, name, index }) {
  const dataContext = useContext(DataContext);

  const [isIncrementing, setIsIncrementing] = useState(false);
  const [isDecrementing, setIsDecrementing] = useState(false);

  // console.log("gender", gender);
  // console.log("name", name);

  // console.log("dataContext[gender]", dataContext[gender]);

  // console.log("dataContext[gender][name]", dataContext[gender][name]);

  useEffect(() => {
    let incrementInterval;
    let decrementInterval;

    if (isIncrementing) {
      incrementInterval = setInterval(() => {
        dataContext.addToCategory(gender, name);
      }, 200); // Increase every 200ms
    }

    if (isDecrementing) {
      decrementInterval = setInterval(() => {
        dataContext.removeFromCategory(gender, name);
      }, 200); // Decrease every 200ms
    }

    // Cleanup intervals when the component unmounts or when isIncrementing/isDecrementing becomes false
    return () => {
      clearInterval(incrementInterval);
      clearInterval(decrementInterval);
    };
  }, [isIncrementing, isDecrementing, dataContext, gender, name]);

  const handleIncrementClick = () => {
    dataContext.addToCategory(gender, name);
  };

  const handleDecrementClick = () => {
    dataContext.removeFromCategory(gender, name);
  };

  return (
    <div
      className={`${styles["counter-container"]} ${
        index % 2 ? styles["dark"] : styles["light"]
      }`}
    >
      <div className={styles["name-container"]}>
        <p>{name}</p>
      </div>

      <div className={styles["button-container"]}>
        <Button
          onMouseDown={() => setIsDecrementing(true)}
          onMouseUp={() => setIsDecrementing(false)}
          onMouseLeave={() => setIsDecrementing(false)}
          onTouchStart={() => setIsDecrementing(true)}
          onTouchEnd={() => setIsDecrementing(false)}
          onTouchMove={() => setIsDecrementing(false)}
          onClick={handleDecrementClick}
          size="small"
          isFullWidth={true}
        >
          -
        </Button>
        <p>{dataContext[gender][name]}</p>
        <Button
          onMouseDown={() => setIsIncrementing(true)}
          onMouseUp={() => setIsIncrementing(false)}
          onMouseLeave={() => setIsIncrementing(false)}
          onTouchStart={() => setIsIncrementing(true)}
          onTouchEnd={() => setIsIncrementing(false)}
          onTouchMove={() => setIsIncrementing(false)}
          onClick={handleIncrementClick}
          size="small"
          isFullWidth={true}
        >
          +
        </Button>
      </div>
    </div>
  );
}

export default Counter;
