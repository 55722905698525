import React, { useState, useContext, useEffect } from "react";
import DataContext from "../../store/data-context";
import { useNavigate, Navigate } from "react-router-dom";
import { refreshTokensAndAWS } from "./refreshTokensAndAWS";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import { logOutUser } from "./logOutUser";
import checkConnection from "./checkConnection";
import { useErrorBoundary } from "react-error-boundary";
import { notifyError } from "../../services/notifyError";

function PrivateRoute({ Component }) {
  const dataContext = useContext(DataContext);
  const navigate = useNavigate();
  const [didSignOut, setDidSignOut] = useState(false);
  const [sessionIsValid, setSessionIsValid] = useState(() => {
    let session =
      typeof localStorage.getItem("sessionData") === "undefined"
        ? null
        : JSON.parse(localStorage.getItem("sessionData"));

    return session;
  });
  const [isOnline, setIsOnline] = useState(false);
  const { showBoundary } = useErrorBoundary();

  const handleOnline = (value) => {
    setIsOnline(value);
    localStorage.setItem("isOnline", value);
  };

  useEffect(() => {
    // events are created which will set the value of isOnline
    window.addEventListener("online", () => {
      handleOnline(true);
    });
    window.addEventListener("offline", () => {
      handleOnline(false);
    });

    return () => {
      // events are removed when the component unmounts
      window.removeEventListener("online", () => {
        handleOnline(true);
      });
      window.removeEventListener("offline", () => {
        handleOnline(false);
      });
    };
  }, []);

  useEffect(() => {
    // After component mounts, the value of isOnline is reset per checkConnection
    checkConnection().then((res) => {
      setIsOnline(res);

      if (res) {
        // Check for errors if online
        notifyError();
      }
    });
  }, []);

  useEffect(() => {
    if (dataContext.username) {
      // If a value is stored for username, the session is re-verified
      const poolData = {
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        ClientId: process.env.REACT_APP_CLIENT_ID,
      };

      const userPool = new CognitoUserPool(poolData);
      const cognitoUser = userPool.getCurrentUser();

      if (!cognitoUser) {
        dataContext.removeUser();
        navigate("/");
      }

      if (didSignOut && cognitoUser) {
        const logout = logOutUser(cognitoUser);
        logout
          .then((res) => {
            console.log(res);
            dataContext.removeUser();
            cognitoUser.signOut();
          })
          .catch((e) => {
            console.error(e);
            showBoundary(e);
          });
      }
      if (isOnline) {
        if (cognitoUser && !didSignOut) {
          refreshTokensAndAWS(cognitoUser)
            .then((res) => {
              setSessionIsValid(res ?? null);
              localStorage.setItem("sessionData", res ?? null);
            })
            .catch((e) => showBoundary(e));

          const refreshInterval = setInterval(() => {
            refreshTokensAndAWS(cognitoUser)
              .then((res) => {
                setSessionIsValid(res ?? null);
                localStorage.setItem("sessionData", res ?? null);
              })
              .catch((e) => showBoundary(e));
          }, 50 * 60 * 1000); // 50 minutes in milliseconds

          // Clean up the interval when the component unmounts
          return () => clearInterval(refreshInterval);
        }
      }
    } else {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.username, navigate, didSignOut, isOnline, sessionIsValid]);

  useEffect(() => {
    if (sessionIsValid === false) {
      const poolData = {
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        ClientId: process.env.REACT_APP_CLIENT_ID,
      };

      const userPool = new CognitoUserPool(poolData);
      const cognitoUser = userPool.getCurrentUser();
      const logout = logOutUser(cognitoUser);
      logout
        .then((res) => {
          cognitoUser.signOut();
          dataContext.removeUser();
          navigate("/");
        })
        .catch((e) => {
          console.log(e);
        });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionIsValid, navigate]);

  const handleBeforeUnload = () => {
    localStorage.setItem("isOnline", false);
    localStorage.setItem("sessionData", true);
    localStorage.setItem("FLAG", isOnline);
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup: Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {sessionIsValid ? (
        <Component
          isOnline={isOnline}
          signOut={() => {
            setDidSignOut(true);
          }}
        />
      ) : (
        <div>Redirigiendo...</div>
      )}
      {sessionIsValid === false && <Navigate to="/" />}
    </>
  );
}

export default PrivateRoute;
