import * as XLSX from "xlsx";

export const downloadAverageAsXLSX = (data) => {
  console.log(data);
  const fullQuestionDictionary = JSON.parse(
    localStorage.getItem("fullQuestionDictionary")
  );
  const valuesArr = [];
  let index = 0;

  for (let i in data) {
    let values = {};
    for (let j in data[i]) {
      //values[fullQuestionDictionary[data[i][j]]] = data[i][j];
      values[fullQuestionDictionary[Object.keys(data[i])[index]]] = data[i][j];
      index++;
    }
    index = 0;
    valuesArr.push({ lote: i, ...values });
  }

  console.log(valuesArr);

  // Create a workbook
  const wb = XLSX.utils.book_new();

  // Create a worksheet
  const ws = XLSX.utils.json_to_sheet(valuesArr);

  XLSX.utils.book_append_sheet(wb, ws, "sheet1");
  XLSX.writeFile(
    wb,
    `promedios-${new Date().toISOString().split("T")[0]}.xlsx`
  );
};
