import React, { useEffect, useContext, useState } from "react";
import DataContext from "../store/data-context";
import classes from "./CounterView.module.css";
import { getMappedLotArr, extractWorkflowSet } from "../utils/dataHandling";
import Zones from "./Zones";
import Lots from "./Lots";
import Stations from "./Stations";
import FormContainer from "./FormContainer";
import ZoneType from "./ZoneType";
import { fetchFromS3 } from "../services/fetchFromS3";
import { getNewestFile } from "../services/getNewestFile";
import Modal from "../components/Modal";

function CounterView({ isOnline }) {
  const [hasInput, setHasInput] = useState(
    JSON.parse(localStorage.getItem("workflowToQuestions")) &&
      !!Object.keys(JSON.parse(localStorage.getItem("workflowToQuestions")))
        .length
  );

  const dataContext = useContext(DataContext);
  const [selection, setSelection] = useState({
    zone: "",
    lot_id: null,
    station_id: null,
  });

  const [typeFilter, setTypeFilter] = useState("");

  const handleCheckbox = (type) => {
    if (!dataContext.lotsData.length) return;

    setTypeFilter(type);
  };

  const resetType = () => {
    setTypeFilter("");
  };

  useEffect(() => {
    // Fetches data, if lots are either not available in localStorage or they have not been fetched and stored yet

    if (isOnline) {
      const retryGetNewestFile = (retries = 3, delay = 2000) => {
        return new Promise((resolve, reject) => {
          getNewestFile()
            .then(resolve)
            .catch((e) => {
              if (retries > 0) {
                console.log(
                  `Retrying getNewestFile... ${retries} attempts left`
                );
                setTimeout(() => {
                  retryGetNewestFile(retries - 1, delay)
                    .then(resolve)
                    .catch(reject);
                }, delay);
              } else {
                reject(e);
              }
            });
        });
      };

      if (!dataContext.lotsData.length) {
        retryGetNewestFile()
          .then((file) => {
            fetchFromS3({
              keyPrefix: `${process.env.REACT_APP_LOTS_FILE_PATH ?? ""}${file}`,
            })
              .then((res) => {
                console.log("response", res.data);
                dataContext.saveApiLotsData(
                  getMappedLotArr(JSON.parse(JSON.stringify(res.data)))
                );
              })
              .catch((e) => console.log(e));
          })
          .catch((e) => console.log("Final error after retries:", e));
      }

      if (
        dataContext.lotsData.length &&
        !Object.keys(dataContext.workflowToQuestions).length
      ) {
        const workflows = extractWorkflowSet(dataContext.lotsData);
        const promises = Array.from(workflows).map((workflow) => {
          return fetchFromS3({
            key: `${process.env.REACT_APP_FILE_PATH ?? ""}${workflow}.csv`,
          });
        });
        Promise.all(promises)
          .then((res) => {
            const workflowToQuestions = Object.assign({}, ...res);
            dataContext.saveQuestionsByWorkFlows(workflowToQuestions);
            setHasInput(true);
          })
          .catch((e) => console.log(e));
      }
    }

    if (dataContext.lot && !dataContext.questions.length) {
      dataContext.saveQuestions();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.lotsData, dataContext.lot, isOnline]);

  //Handles the selected lot and station.
  const handleClick = (key, value) => {
    setSelection((prevSelection) => ({
      ...prevSelection,
      [key]: value,
    }));
  };

  // Allows user to reselect values.
  const handleReset = (value) => {
    if (value === "zone") {
      setSelection({
        zone: "",
        lot_id: null,
        station_id: null,
      });
    }

    if (value === "lot_id") {
      setSelection((prevState) => ({
        ...prevState,
        lot_id: null,
        station_id: null,
      }));
      dataContext.clearLotData();
    }

    if (value === "station_id") {
      setSelection((prevState) => ({
        ...prevState,
        station_id: null,
      }));
      dataContext.clearStationData();
    }
  };

  const handleResetAllSelection = () => {
    setSelection({
      zone: "",
      lot_id: null,
      station_id: null,
    });
  };

  return (
    <>
      <div className={classes["component-container"]}>
        {!typeFilter && !dataContext.lot ? (
          <ZoneType handleCheckbox={handleCheckbox}>
            Seleccione el tipo a contar
          </ZoneType>
        ) : (
          ""
        )}
        {dataContext.lotsData.length && typeFilter && !selection.zone ? (
          <Zones
            handleReset={resetType}
            type={typeFilter}
            handleClick={handleClick}
          />
        ) : (
          ""
        )}
        {selection.zone && !dataContext.lot && (
          <Lots
            zone={selection.zone}
            type={typeFilter}
            handleClick={handleClick}
            handleReset={handleReset}
          />
        )}
        {dataContext.lot &&
          !dataContext.station &&
          dataContext.questions.length && (
            <Stations
              isOnline={isOnline}
              handleClick={handleClick}
              lot_id={dataContext.lot.feature_id}
              handleReset={handleReset}
              handleResetAllSelection={handleResetAllSelection}
            />
          )}
        {dataContext.lot &&
          dataContext.station &&
          dataContext.questions.length && (
            <FormContainer handleReset={handleReset} />
          )}
      </div>
      <Modal open={!hasInput} isConfirmationMessage={true}>
        Recibiendo datos
      </Modal>
    </>
  );
}

export default CounterView;
