import React, { useState, useEffect } from "react";
import LotOnRecordV2 from "./LotOnRecordV2";
import { SearchField } from "@aws-amplify/ui-react";
import {
  StyledScrollView,
  StyledHeading,
  ReturnButton,
} from "../utils/styledComponents";
import classes from "./Lots.module.css";

function LotsOnRecordV2({ data, handleReturn, handleCurrentLot, zone }) {
  const [value, setValue] = useState("");

  let lots = data.allEntries.filter((entry) => entry.zone === zone);
  lots = lots.map((entry) => entry.lot_name);
  lots = [...new Set(lots)];
  console.log(lots);

  const [filteredLots, setFilteredLots] = useState(lots); // los lotes luego son filtrados por el search bar

  useEffect(() => {
    if (value) {
      const filteredData = lots.filter((item) =>
        item.toLowerCase().includes(value.toLowerCase())
      );

      setFilteredLots(filteredData);
    } else {
      setFilteredLots(lots);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onChange = (e) => {
    if (e.target.name === "Search") {
      setValue(e.target.value);
    }
  };

  const onClear = () => {
    setValue("");
    setFilteredLots(lots);
  };

  const myStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    margin: "1rem 0px 2rem 0px",
    width: "22rem",
    alignSelf: "center",
  };

  return (
    <div style={myStyle}>
      <StyledHeading level={4}>Seleccione un lote</StyledHeading>
      <span className={classes.spacing} />

      <SearchField
        label="Search"
        placeholder="Buscar..."
        hasSearchButton={false}
        hasSearchIcon={true}
        onChange={onChange}
        value={value}
        onClear={onClear}
        name="Search"
      />

      <StyledScrollView height="20rem">
        {filteredLots.map((lot, index) => (
          <LotOnRecordV2
            name={lot}
            key={index}
            handleCurrentLot={handleCurrentLot}
          />
        ))}
      </StyledScrollView>
      <div className={classes["bottom-buttons"]}>
        {" "}
        <ReturnButton
          isFullWidth={true}
          onClick={() => {
            handleReturn(true);
          }}
        >
          Volver
        </ReturnButton>
      </div>
    </div>
  );
}

export default LotsOnRecordV2;
