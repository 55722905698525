import React, { useEffect, useState } from "react";
import styles from "./ZoneType.module.css";
import { ActionButton, StyledHeading } from "../utils/styledComponents";
import { RadioGroupField, Radio } from "@aws-amplify/ui-react";
import { getDailyAverage } from "../services/getDailyAverage";
import AverageLotsTable from "./AverageLotsTable";
import Modal from "../components/Modal";

function MainRecordsContainer({ handleChoice, children, isOnline }) {
  const [localType, setLocalType] = useState("conteos");
  const [averages, setAverages] = useState({});
  const [didRequest, setDidRequest] = useState(false);
  const [isEmpty, setIsEmpty] = useState(null);

  const handleSelection = (e) => {
    setLocalType(e.target.value);
  };

  const handleCheckbox = () => {
    if (localType === "promedios") {
      if (didRequest) {
        return;
      } else {
        console.log("fetched");

        getDailyAverage()
          .then((res) => {
            setAverages(res);
            setDidRequest(true);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }

    handleChoice(localType);
  };
  useEffect(() => {
    if (didRequest) {
      if (!Object.keys(averages).length) {
        console.log(averages, Object.keys(averages).length);
        setIsEmpty(true);
        setTimeout(() => {
          setIsEmpty(null);
          setDidRequest(false);
        }, 2000);
      } else {
        setIsEmpty(false);
      }
    }
  }, [averages, didRequest]);

  return (
    <>
      {isEmpty === null || isEmpty === true ? (
        <div className={styles["zone-container"]}>
          <div className={styles.top}>
            <StyledHeading level={4}>{children}</StyledHeading>

            <div className={styles["button-container"]}>
              <RadioGroupField name="Tipos" size="large" fontWeight={"bold"}>
                <Radio
                  className={styles.button}
                  onChange={handleSelection}
                  checked={localType === "conteos"}
                  value="conteos"
                >
                  <div style={{ color: "#2f4250" }}>Conteos por estación</div>
                </Radio>
                <Radio
                  className={styles.button}
                  onChange={handleSelection}
                  checked={localType === "promedios"}
                  value="promedios"
                >
                  <div style={{ color: "#2f4250" }}>Promedios por lote</div>
                </Radio>
              </RadioGroupField>
            </div>
          </div>

          <div className={styles["bottom-buttons"]}>
            <ActionButton
              variation="primary"
              onClick={() => {
                handleCheckbox();
              }}
            >
              Confirmar
            </ActionButton>
          </div>
        </div>
      ) : (
        ""
      )}
      {isEmpty === false ? (
        <AverageLotsTable
          data={averages}
          isOnline={isOnline}
          handleReturn={() => {
            setAverages({});
            setDidRequest(false);
            setIsEmpty(null);
          }}
          resetRequest={() => {
            setDidRequest(false);
          }}
        />
      ) : (
        ""
      )}
      <Modal
        children={"No se encontraron registros"}
        isConfirmationMessage={true}
        open={isEmpty}
      />
    </>
  );
}

export default MainRecordsContainer;
