function checkConnection() {
  return new Promise((resolve) => {
    // Pings sample url. If reponse !200 user is deemed offline
    const apiUrl = "https://jsonplaceholder.typicode.com/posts/1";

    fetch(apiUrl)
      .then((response) => {
        if (response.status === 200) {
          console.log("Internet connection is working");
          resolve(true);
        } else {
          console.log(
            "Internet connection is not working or returned an error"
          );
          resolve(false);
        }
      })
      .catch((error) => {
        console.error(
          "Internet connection is not working or returned an error",
          error
        );
        resolve(false);
      });
  });
}

export default checkConnection;
