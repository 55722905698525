import React, { useState, useEffect } from "react";
import classes from "./AverageLotsTable.module.css";
import { SimpleHeading, ActionButton } from "../utils/styledComponents";
import { transformArray } from "../utils/dataHandling";
import { downloadAverageAsXLSX } from "../utils/downloadAverageAsXLSX";

const AverageLotsTable = ({ data, handleReturn, resetRequest, isOnline }) => {
  useEffect(() => {
    resetRequest();
    // eslint-disable-next-line
  }, []);

  const mappedData = [];

  for (let i in data) {
    mappedData.push({ Lote: i, ...data[i] });
  }

  const outputObject = transformArray(mappedData);

  const [scrollTop, setScrollTop] = useState();
  const [scrollLeft, setScrollLeft] = useState();
  const [direction, setDirection] = useState("");

  // // The dictionary will render the textorg_ARG value instead the questioncode
  const dictionary = JSON.parse(localStorage.getItem("fullQuestionDictionary"));

  if (!dictionary) return <></>; // Nothing is rendered if variable is not in storage. This prevents reading properties of null when user logs out from this page

  const values = [];

  // jsx for each row is stored in "row"
  Object.keys(mappedData[0]).forEach((fieldName, rowIndex) => {
    const row = outputObject[fieldName].map((value, colIndex) => {
      return (
        <div
          key={colIndex}
          className={`${classes["value"]} ${
            rowIndex % 2 ? classes["dark"] : classes["light"]
          } `}
          value={value}
        >
          {typeof value === "number" ? Number(value.toFixed(3)) : value}
        </div>
      );
    });

    // // jsx for each row is pushed into values array
    values.push(
      <div
        key={rowIndex}
        className={`${classes["grid-column-values"]} ${
          rowIndex === 0 && classes["first-row"]
        }
        ${
          rowIndex === 0 &&
          scrollTop &&
          direction === "vertical" &&
          classes["bottom-shadow"]
        }`}
      >
        {row}
      </div>
    );
  });

  // Handling dynamic box-shadow

  const handleScroll = (event) => {
    const verticalDelta = Math.abs(event.currentTarget.scrollTop - scrollTop);
    const horizontalDelta = Math.abs(
      event.currentTarget.scrollLeft - scrollLeft
    );

    if (verticalDelta > horizontalDelta) {
      setDirection("vertical");
    } else if (horizontalDelta > verticalDelta) {
      setDirection("horizontal");
    }
    setScrollLeft(event.currentTarget.scrollLeft);
    setScrollTop(event.currentTarget.scrollTop);
  };

  let headers = Object.keys(mappedData[0]).map((fieldName, index) => (
    <div
      key={index}
      className={`${classes["grid-cell"]} ${
        index % 2 ? classes["dark"] : classes["light"]
      } ${index === 0 && classes["first-row"]}
      ${
        index === 0 &&
        scrollTop &&
        direction === "vertical" &&
        classes["bottom-shadow"]
      } ${classes["smaller-font"]}
     `}
    >
      {dictionary[fieldName] ?? fieldName}
    </div>
  ));

  const fecha = new Date();
  const opciones = { year: "numeric", month: "long", day: "numeric" };
  const fechaFormateada = fecha.toLocaleString("es-ES", opciones);
  return (
    <>
      <div className={classes["records-container"]}>
        <SimpleHeading level={5}>Promedios por lote </SimpleHeading>
        <div className={classes.date}>{fechaFormateada}</div>
        <div onScroll={handleScroll} className={classes["grid-container"]}>
          <div
            className={`${classes["grid-column"]} ${
              scrollLeft &&
              direction === "horizontal" &&
              classes["right-shadow"]
            }`}
          >
            {headers}
          </div>
          <div className={classes.scroll}>
            <div className={classes["values-container"]}>{values}</div>
          </div>
        </div>
        <div className={classes["button-container"]}>
          <ActionButton
            variation="primary"
            onClick={() => {
              downloadAverageAsXLSX(data);
            }}
          >
            Descargar
          </ActionButton>
          <ActionButton onClick={handleReturn}>
            <div style={{ color: "#2f4250" }}>Regresar</div>
          </ActionButton>
        </div>
      </div>
    </>
  );
};
export default AverageLotsTable;
