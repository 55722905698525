import React, { useState } from "react";
import { Tabs, TabItem } from "@aws-amplify/ui-react";
import CounterView from "./CounterView"; /*CounterView will render the seed-tracking flow*/
import History from "./History"; /*History will render the flow for retrieval of recorded data */
import Modal from "../components/Modal";
import { NavButton } from "../utils/styledComponents";
import classes from "./Home.module.css";
import CenteringCard from "../components/CenteringCard";
import UploadToCloud from "../components/UploadToCloud";

function Home({ signOut, isOnline }) {
  const [isModalOpen, setIsModalOpen] = useState(false); // Opens confirmation dialog when user wants to sign out.

  return (
    <>
      <Tabs defaultIndex={0} className={classes.tabs}>
        <TabItem title="Contador" className={classes["tabs-title"]}>
          <CenteringCard>
            <CounterView signOut={signOut} isOnline={isOnline} />
          </CenteringCard>
        </TabItem>
        <TabItem title="Registros" className={classes["tabs-title"]}>
          <CenteringCard>
            <History signOut={signOut} isOnline={isOnline} />
          </CenteringCard>
        </TabItem>

        <div className={classes["right-side-container"]}>
          <UploadToCloud isOnline={isOnline} />
          <NavButton
            onClick={() => {
              isOnline
                ? setIsModalOpen(true)
                : alert("No tienes conexión a internet");
            }}
          >
            Cerrar sesión
          </NavButton>
        </div>
        {isModalOpen && (
          <Modal
            open={isModalOpen}
            onClose={() => {
              setIsModalOpen(false);
            }}
            onConfirm={() => {
              signOut();
            }}
          >
            ¿Está seguro de cerrar la sesión?
          </Modal>
        )}
      </Tabs>
    </>
  );
}

export default Home;
