import React from "react";
import { StyledButton } from "../utils/styledComponents";
import { Text } from "@aws-amplify/ui-react";
import styles from "../components/Lot.module.css";

function LotOnRecordV2({ name, handleCurrentLot }) {
  return (
    <StyledButton
      variation="primary"
      onClick={() => {
        handleCurrentLot(name);
      }}
    >
      <div className={styles["lot-container"]}>
        {<Text color="white">{name}</Text>}
      </div>
    </StyledButton>
  );
}

export default LotOnRecordV2;
