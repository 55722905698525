import React, { useState } from "react";
import styles from "./ZoneType.module.css";
import { ActionButton, StyledHeading } from "../utils/styledComponents";
import { RadioGroupField, Radio } from "@aws-amplify/ui-react";

function ZoneType({ handleCheckbox, children, handleChoice, hasReturnButton }) {
  const [localType, setLocalType] = useState("Commercial");

  const handleSelection = (e) => {
    setLocalType(e.target.value);
  };

  return (
    <div className={styles["zone-container"]}>
      <div className={styles.top}>
        <StyledHeading level={4}>{children}</StyledHeading>

        <div className={styles["button-container"]}>
          <RadioGroupField name="Tipos" size="large" fontWeight={"bold"}>
            <Radio
              className={styles.button}
              onChange={handleSelection}
              checked={localType === "Commercial"}
              value="Commercial"
            >
              <div style={{ color: "#2f4250" }}>Comercial</div>
            </Radio>
            <Radio
              className={styles.button}
              onChange={handleSelection}
              checked={localType === "Foundation"}
              value="Foundation"
            >
              <div style={{ color: "#2f4250" }}>Fundadora</div>
            </Radio>
          </RadioGroupField>
        </div>
      </div>
      <div className={styles["bottom-buttons"]}>
        <ActionButton
          variation="primary"
          onClick={() => {
            handleCheckbox(localType);
          }}
        >
          Confirmar
        </ActionButton>
        {hasReturnButton && (
          <ActionButton
            onClick={() => {
              handleChoice();
            }}
          >
            Regresar
          </ActionButton>
        )}
      </div>
    </div>
  );
}

export default ZoneType;
