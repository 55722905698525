import React, { useContext, useState, useEffect } from "react";
import classes from "./UploadToCloud.module.css";
import DataContext from "../store/data-context";
import { extractLotNames } from "../utils/dataHandling";
import { writeToDynamoDB } from "../services/writeToDynamoDB";
import Modal from "./Modal";

const UploadToCloud = ({ isOnline }) => {
  const dataContext = useContext(DataContext);
  const [isModalOpen, setIsModalOpen] = useState({
    submitted: false,
    loading: false,
  });
  const { finalLots } = dataContext;
  const [dynamoResponse, setDynamoResponse] = useState("");

  const handleSend = () => {
    if (!finalLots.length) return;

    // Ejecuta función para enviar los datos a la tabla de DynamoDB
    writeToDynamoDB(finalLots)
      .then((res) => {
        console.log(res.message);
        setIsModalOpen((prevState) => ({ ...prevState, loading: false }));
        dataContext.clearFinalLotsArray();
        setDynamoResponse(
          res.statusCode === 200
            ? "Los datos fueron enviados con éxito"
            : res.error
        );
      })
      .catch((e) => {
        console.log(e);
        setIsModalOpen((prevState) => ({ ...prevState, loading: false }));
        setDynamoResponse(e.message);
      });
  };

  useEffect(() => {
    if (dynamoResponse) {
      // SET A TIMEOUT TO REDERICT (CLEAR DATA, ACTUALLY) AFTER DATE IS SENT
      // setTimeout()

      setTimeout(() => {
        setDynamoResponse("");
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dynamoResponse]);

  return (
    <div className={classes["icon-container"]}>
      <img
        onClick={() => {
          if (!finalLots.length) return;
          if (!isOnline) {
            alert("No hay conexión a internet");
            return;
          }

          setIsModalOpen((prevState) => ({
            ...prevState,
            submitted: true,
          }));
        }}
        src="upload-alt-2-svgrepo-com.svg"
        alt="Icon"
        style={{
          maxWidth: "3rem",
          maxHeight: "6rem",
          border: "3px solid whitesmoke",
        }}
      />
      {finalLots.length ? (
        <div className={classes["pending"]}>
          {extractLotNames(finalLots).length}
        </div>
      ) : (
        ""
      )}
      <Modal
        open={isModalOpen.submitted}
        onClose={(e) => {
          e.stopPropagation();
          setIsModalOpen({
            loading: false,
            submitted: false,
          });
        }}
        onConfirm={(e) => {
          e.stopPropagation();
          handleSend();

          setIsModalOpen({
            loading: true,
            submitted: false,
          });
        }}
      >
        <>
          <div> ¿Desea enviar los siguientes lotes?</div>
          <div className={classes["lot-container"]}>
            {extractLotNames(finalLots).map((lot) => (
              <div style={{ fontWeight: "bold" }} key={lot}>
                {lot}
              </div>
            ))}
          </div>
        </>
      </Modal>

      <Modal isConfirmationMessage={true} open={dynamoResponse}>
        {dynamoResponse}
      </Modal>
      <Modal isLoadingMessage={true} open={isModalOpen.loading}>
        Enviando resultados...
      </Modal>
    </div>
  );
};

export default UploadToCloud;
