import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import FallbackRender from "./FallbackRender";
import { newErrorHandler } from "./utils/dataHandling";

const errorHandler = (error) => {
  // if error is not already in errorBatch it will be added to it
  const errorBatch = JSON.parse(localStorage.getItem("errorBatch"));

  const errorObj = {
    didNotify: false,
    error: {
      message: error.message,
      stack: error.stack,
    },
  };

  // returns true is no entries in errorBatch match the new error stack

  if (!errorBatch) {
    localStorage.setItem("errorBatch", JSON.stringify([errorObj]));
  } else if (newErrorHandler(errorObj.error.stack, errorBatch)) {
    // true if new error stack

    errorBatch.push(errorObj);
    localStorage.setItem("errorBatch", JSON.stringify(errorBatch));
  }
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ErrorBoundary
      FallbackComponent={FallbackRender}
      onError={errorHandler}
      onReset={() => window.location.reload()}
    >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

serviceWorkerRegistration.register({
  onUpdate: async (registration) => {
    // Corremos este código si hay una nueva versión de nuestra app
    // Detalles en: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
    if (registration && registration.waiting) {
      await registration.unregister();
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      // Des-registramos el SW para recargar la página y obtener la nueva versión. Lo cual permite que el navegador descargue lo nuevo y que invalida la cache que tenía previamente.
      window.location.reload();
    }
  },
});
